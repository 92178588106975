<template>
  <div>
    <div class="row" v-if="content">
      <div class="col-3">
        <div class="card card-profile">
          <app-image
            v-if="hasMedias && !isVideo"
            :src="content.medias[0].media"
            :alt="content.campaign_creator.creator.username"
            class="card-img-top"
          />
          <video v-if="hasMedias && isVideo" class="card-img-top" controls>
            <source :src="content.medias[0].media" type="video/mp4" />
          </video>
          <div class="card-body pt-3">
            <div class="row align-items-center">
              <div class="col-auto">
                <div class="avatar avatar-sm rounded-circle">
                  <app-image :src="creator.picture" :alt="creator.username" />
                </div>
              </div>
              <div class="col ml--3">
                <h5 class="mb-0">
                  {{ creator.name | truncate(20) }}
                </h5>
                <p class="text-xs text-muted mb-0">@{{ creator.username }}</p>
              </div>
              <div class="col-auto">
                <el-dropdown trigger="click">
                  <base-button
                    class="table-action-btn"
                    type="secondary"
                    outline
                    native-type="button"
                    size="sm"
                  >
                    <i class="fas fa-ellipsis-v"></i>
                  </base-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="updateData">
                      <i class="fas fa-sync-alt text-xs mr-2"></i>
                      {{
                        $t('views.app.campaigns.contents.update_data_button')
                      }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="row justify-content-around" v-if="hasStats">
          <div class="col-3" v-for="(statistic, index) in stats" :key="index">
            <stats-card
              :sub-title="
                Number(statistic.value)
                  ? statistic.value
                  : 0 | number(statistic.pattern)
              "
              :title="statistic.label"
              :type="statistic.color"
              :icon="statistic.icon"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-table type="comments" v-if="hasComments" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      showModal: false,
      form: null,
    }
  },

  computed: {
    ...mapGetters({
      content: 'contents/getContent',
      checkComments: 'contents/checkComments',
    }),

    contentId() {
      return this.$route.params.contentId
    },

    creator() {
      return this.content.campaign_creator.creator
    },

    hasMedias() {
      return this.content.medias.length > 0
    },

    isVideo() {
      return this.hasMedias && this.content.medias[0].is_video
    },

    stats() {
      if (!this.form) {
        return []
      }
      return this.form.schema.map(field => {
        field.value = this.content.stats[field.key]
        return field
      })
    },

    hasStats() {
      if (!this.content || !this.form) {
        return false
      }
      return !!Object.keys(this.content.stats || {})
    },

    hasComments() {
      if (!this.content) {
        return false
      }

      return this.checkComments({
        provider: this.content.provider.slug,
        type: this.content.type.slug,
      })
    },
  },

  methods: {
    ...mapActions({
      contentShow: 'contents/show',
      contentUpdateData: 'contents/updateData',
      contentDynamicForm: 'contents/dynamicForm',
    }),

    async updateData() {
      const { ok } = await this.contentUpdateData(this.contentId)

      this.$notify({
        type: ok ? 'success' : 'danger',
        message: ok
          ? this.$t(
              'views.app.campaigns.contents.alerts.update_data.success_message'
            )
          : this.$t(
              'views.app.campaigns.contents.alerts.update_data.error_message'
            ),
      })
    },
  },

  async mounted() {
    await this.contentShow(this.contentId)
    const {
      provider: { slug: provider },
      type: { slug: type },
    } = this.content
    const form = await this.contentDynamicForm({ provider, type })
    this.form = form
  },
}
</script>
